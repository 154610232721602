

.tf_select_btn:hover{
    background-color:rgba(0,0,255,0.65);
    color:white;
}



.HistogramBarClass{
    transition: height 1s ease-in-out
}
