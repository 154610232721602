
/*
body{
    background-color:'red';
    color:'red'
}*/
/*
#victest{
    color:'red';
    margin-top:100px
}*/

/*
.drawingToolClass:hover{
    background-color:rgba(0,0,255,0.5)
}*/


/*
.zoomOutBtnClass .zoomInBtnClass{
    position: relative;
}



.zoomOutBtnClass:hover::after {
    content: "zoomOut";
    position: absolute;
    top: 100%;
    right: 0;
    background-color: black;
    padding: 5px;
    color:white;
    font-size:12px
  }

  .zoomInBtnClass:hover::after {
    content: "zoomIn";
    position: absolute;
    top: 100%;
    right: 0;
    background-color: black;
    padding: 5px;
    color:white;
    font-size:12px
  }






  
.lockBtnClass .unLockBtnClass{
    position: relative;
}



.lockBtnClass:hover::after {
    content: "lockChart";
    position: absolute;
    top: 100%;
    right: 0;
    background-color: black;
    padding: 5px;
    color:white;
    font-size:12px
  }

  .unLockBtnClass:hover::after {
    content: "unlockChart";
    position: absolute;
    top: 100%;
    right: 0;
    background-color: black;
    padding: 5px;
    color:white;
    font-size:12px
  }
*/


.allowTouchBtn:hover{
  transform:scale(1.5);
}


.csmBtnTool .unTouchableChartBtnTool .touchableChartBtnTool  .highlightCandleCSMBtnTool .psychoTool .psychoValBtnTool .deleteBtnTool .unlockBtnTool .unlockBtn2Tool .snapShotBtnTool .moreTools .lessTools .unHideAllTLTool .hideAllTLTool .deleteAllTLTool .textTool .crossHairTool .arrowTool .replayTool .lockBtnTool .lockBtn2Tool .settingsBtnTool{
    position: relative;
}


.psychoTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Psychological Level Lines";
  position: absolute;
  /*top: 0%;*/
  left: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}

.psychoValBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Psycho levels";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  background-color: rgba(0, 0, 0, 1);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}

.deleteBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Delete";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  background-color: rgba(0, 0, 0, 1);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}

.snapShotBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Snapshot";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  background-color: rgba(0, 0, 0, 1);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}

.highlightCandleCSMBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Highlight Candle strength";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  right:0px;
  background-color: rgba(0, 0, 0, 1);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}

.unhighlightCandleCSMBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Unhighlight Candle strength";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  right:0px;
  background-color: rgba(0, 0, 0, 1);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}


.settingsBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Settings";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  background-color: rgba(0, 0, 0, 1);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}


.touchableChartBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Touchable Chart";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  right:0px;
  background-color: rgba(0, 0, 0, 1);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}

.unTouchableChartBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Untouchable Chart";
  position: absolute;
  /*top: 0%;*/
  top: 100%;  
  right:0px;
  background-color: rgba(0, 0, 0, 1);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}


.unlockBtn2Tool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "UnLock Replay Line from All Charts";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}


.lockBtn2Tool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Lock Replay Line Onto All Charts";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}


.unlockBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "UnLock Line from All Charts";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}


.lockBtnTool:hover::after {
  white-space: nowrap; /* Prevent text from wrapping */
  content: "Lock Line Onto All Charts";
  position: absolute;
  /*top: 0%;*/
  top: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  color:white;
  font-size:11px;
  /*height:10px;*/
  display:flex;
  justify-content:center;
  align-items: center;
}


.arrowTool:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "NO - cross hair";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }


.crossHairTool:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "Cross Hair";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }


.textTool:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "Text";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }

  .replayTool:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "Chart candlesticks Replay";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }

  .csmBtnTool:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "Historical strength meter & Correlation";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }

.deleteAllTLTool:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "Delete All drawings";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }

  .hideAllTLTool:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "Hide All drawings";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }


.unHideAllTLTool:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "All drawings Visible";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }


.moreTools:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "more tools";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }

  .lessTools:hover::after {
    white-space: nowrap; /* Prevent text from wrapping */
    content: "less tools";
    position: absolute;
    /*top: 0%;*/
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    color:white;
    font-size:11px;
    /*//height:10px;*/
    display:flex;
    justify-content:center;
    align-items: center;
  }


