
/*
.pagesDropDown:hover + .pagesDropDownIcon {
    color: red; 
}*/

body{
    overflow-x: hidden;
}


.pagesDropDown:hover{
    background-color:rgba(0,0,255,0.65);
    color:white;
}
/*
.alertIcon:hover{
    background-color:rgba(0,0,255,0.65);
    color:white;
}*/


.hosting_container{
    width: 200%;
    /*height:50%;*/ /*giving a parent-height: restricts how well various embedded-children will fit, and: without a height, they fit perfectly*/
    
    position: relative; /*a parent-container: must be relative/related to its embedded-children in order to bound various absolute-children within its borders*/
}



.parent1{
    /*height: 120px;*/
    width: 100%;

    position: relative;
    display: flex;
    justify-content: space-around;

    animation-name:rotation_animation1;
    /*animation-duration:100s;*/
    animation-duration: 100s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}

.parent2{
    /*height: 120px;*/
    width: 100%;

    position: absolute;/*a child's-position: is made absolute, in the benefit of absolutely removing it from the document-flow: positioned at a given -end within its related-parent-borders */
    display: flex;
    justify-content: space-around;

    
    top: 0%;
    left: 100%;

    animation-name:rotation_animation2;
    /*animation-duration:100s;*/
    animation-duration: 100s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}




@keyframes rotation_animation1{
    0%{
      left:0%;
    }
    
    100%{
      left:-100%;
    }
  }

@keyframes rotation_animation2{
    0%{
        left:100%;
    }

    100%{
        left:0%;
    }
}



.pageDropDownScroolBar::-webkit-scrollbar {
    width: 0.65em;
    /*height: 0.7em;*/
    /*cursor: pointer;*/
}
 
.pageDropDownScroolBar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 255, 0.5);
    border-radius: 15px;
}
 
.pageDropDownScroolBar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 255, 0.7);
  /*outline: 1px solid slategrey;*/
  border-radius: 15px;
}


.pageDropDownScroolBar2::-webkit-scrollbar {
  width: 0.5em;
  /*height: 0.7em;*/
  /*cursor: pointer;*/
}

.pageDropDownScroolBar2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 215, 0, 0.6);
  border-radius: 15px;
}

.pageDropDownScroolBar2::-webkit-scrollbar-thumb {
background-color: rgba(255, 215, 0, 0.99);
/*outline: 1px solid slategrey;*/
border-radius: 15px;
}


.pageDropRightScroolBar::-webkit-scrollbar {
  /*width: 0.3em;*/
  height: 0.7em;
  /*cursor: pointer;*/
}

.pageDropRightScroolBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 255, 0.5);
  border-radius: 15px;
  
}

.pageDropRightScroolBar::-webkit-scrollbar-thumb {
background-color: rgba(0, 0, 255, 0.7);
/*outline: 1px solid slategrey;*/
border-radius: 15px;

}


/* Define the keyframes for the animation */
@keyframes moveRight {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Apply the animation to an element */
.coin_left_right {
  animation: moveRight 2s linear infinite;
}



.coin_left_right1 {
  /*width: 100px;
  height: 100px;
  background-color: gold;
  border-radius: 50%;*/
  
  animation: rotate-coin 1s infinite linear;
}

@keyframes rotate-coin {
  0% {
    transform: scale(1);
  }
  50% { 
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.3);
  }
}





  .coin {
    animation: rotate-coin 1s infinite linear;
  }
  
  @keyframes rotate-coin {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1.3);
    }
  }


  .coin2 {
    /*width: 100px;
    height: 100px;
    background-color: gold;
    border-radius: 50%;*/
    animation: rotate-coin2 2s infinite linear;
  }

  
  
  @keyframes rotate-coin2 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1.3);
    }
  }



  .coin3 {
    /*width: 100px;
    height: 100px;
    background-color: gold;
    border-radius: 50%;*/
    animation: rotate-coin3 2s infinite linear;
  }

  @keyframes rotate-coin3 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1.2);
    }
  }


  .coin4 {
    /*width: 100px;
    height: 100px;
    background-color: gold;
    border-radius: 50%;*/
    animation: rotate-coin3 1s infinite linear;
  }

  @keyframes rotate-coin3 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(2);
    }
  }


  .coin5 {
    animation: rotate-coin5 1s infinite linear;
  }
  
  @keyframes rotate-coin5 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    70% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1.99);
    }
  }




/* Media query for smaller screens 
hide something when screen small
*/
@media (max-width: 600px) {
    #example {
      display: none;
    }
  }
  
  /* Media query for larger screens 
  show something when screen is large
  */
  @media (min-width: 1200px) {
    #example {
      display: flex;
    }
  }


