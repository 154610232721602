html{
    /*make the page unselectable*/
    /*user-select:none;*/
}

.unselectable{
    /*text-align: center;*/
    /*user-select:none;*/
}


#myDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #f0f0f0;
    transition: top 0.3s ease-in-out;
    }
  
.scroll-hide {
top: -50px;
}


/*
body::-webkit-scrollbar {
    width: 0.8em;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 15px;
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgba(173, 173, 173, 0.787);
  /*outline: 1px solid slategrey;*/
  /*border-radius: 15px;
}*/


body{
    background-color: rgb(244, 245, 250);
    overflow-x: hidden;
}







/*
.chartBarScrollClass::-webkit-scrollbar {
    width: 1em;
    height: 0.7em;
    cursor: pointer;
}
 
.chartBarScrollClass::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 15px;
    
}
 
.chartBarScrollClass::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.152);
  border-radius: 15px;
  
}*/



.chartBarScrollPhoneClass::-webkit-scrollbar {
    width: 1em;
    height: 0.3em;
}
 
.chartBarScrollPhoneClass::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 15px;
}
 
.chartBarScrollPhoneClass::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.152);
  /*outline: 1px solid slategrey;*/
  border-radius: 15px;
}



@media (max-width: 500px) {
    .homeBodyWidthResizer{ 
        width: 95%;
    }
    .containerWidth{
        justify-content: start;
    }
}
@media (max-width: 500px) {
    .pagesHeaderWidthAdjuster, .parentHeader{ 
        width: 100%;
    }
    .aLink{
        margin-left:0px
    }
}
@media (max-width: 700px) {
    .parentHeader{ 
        width: 100%;
        justify-content: center;
    }
}
@media (min-width: 700px) {
    .aLink{
        margin-left:27px
    }
}
@media (min-width: 501px) {
    .homeBodyWidthResizer, .pagesHeaderWidthAdjuster, .parentHeader{
        width: 95%;
    }
    
}
@media (min-width: 900px) {
    .homeBodyWidthResizer, .pagesHeaderWidthAdjuster, .parentHeader{
        width: 85%;
    }
}
@media (min-width: 950px) {
    .homeBodyWidthResizer, .pagesHeaderWidthAdjuster, .parentHeader{
        width: 80%;
    }
}
@media (min-width: 1000px) {
    .homeBodyWidthResizer, .pagesHeaderWidthAdjuster, .parentHeader{
        width: 75%;
    }
}
@media (min-width: 1050px) {
    .homeBodyWidthResizer, .pagesHeaderWidthAdjuster, .parentHeader{
        width: 70%;
    }
}
@media (min-width: 1150px) {
    .homeBodyWidthResizer, .pagesHeaderWidthAdjuster, .parentHeader{
        width: 65%;
    }
}



@media (max-width: 500px) {
    .homeBodyMarginTop{
        margin-top: 0px;
    }
}
@media (min-width: 500px) {
    .homeBodyMarginTop{
        margin-top: 12px;
    }
}



.teleTranColor {
    /*width: 100px;
    height: 100px;
    background-color: red;*/
    animation: colorAnimation 2s infinite alternate;
  }
  
  @keyframes colorAnimation {
    0% {
      background-color: rgb(91, 52, 221);
    }
    100% {
      background-color: rgb(0, 0, 0);
    }
  }


/*
::-webkit-scrollbar addresses the background of the bar itself. It is usually covered by the other elements
::-webkit-scrollbar-button addresses the directional buttons on the scrollbar
::-webkit-scrollbar-track addresses the empty space “below” the progress bar
::-webkit-scrollbar-track-piece is the top-most layer of the the progress bar not covered by the draggable scrolling element (thumb)
::-webkit-scrollbar-thumb addresses the draggable scrolling element that resizes depending on the size of the scrollable element
::-webkit-scrollbar-corner addresses the (usually) bottom corner of the scrollable element, where two scrollbars might meet
::-webkit-resizer addresses the draggable resizing handle that appears above the scrollbar-corner at the bottom corner of some elements
*/



/*CSM css*/

/* Default width */

.csmBodyWidthResizer {
    width: 95%;
}

@media (min-width: 900px) {
    .csmBodyWidthResizer {
        width: 75%;
    }
}

@media (min-width: 950px) {
    .csmBodyWidthResizer {
        width: 70%;
    }
}

@media (min-width: 1000px) {
    .csmBodyWidthResizer {
        width: 65%;
    }
}

@media (min-width: 1050px) {
    .csmBodyWidthResizer {
        width: 60%;
    }
}

@media (min-width: 1150px) {
    .csmBodyWidthResizer {
        width: 55%;
    }
}







